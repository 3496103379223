<template>
  <div class="modal" :class="{ show: props.show }">
    <div class="container">
      <div class="mhead">
        <a @click="onCancel" class="close"></a>
      </div>
      <div class="mbody">
        <div class="icon warning"></div>
        <div class="msg">删除商品</div>
        <div class="info">您可将商品移入收藏或者删除</div>
      </div>
      <div class="mfoot">
        <a class="move" @click="onFavor">移入收藏夹</a>
        <a class="submit" @click="onRemove">删除商品</a>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";

export default {
  props: ["show"],

  setup(props, { emit }) {
    const state = reactive({});

    watch(
      () => props.show,
      (newdata) => {
        if (newdata) {
          document.body.classList.add("modal_open");
        } else {
          document.body.classList.remove("modal_open");
        }
      }
    );

    const onCancel = () => {
      emit("onCancel", {});
    };

    const onFavor = () => {
      emit("onFavor", {});
    };

    const onRemove = () => {
      emit("onRemove", {});
    };

    return { state, props, onCancel, onFavor, onRemove };
  },
};
</script>

<style lang="less" scoped>
@import "./modal.less";
.modal .container {
  width: 430px !important;

  .mhead {
    height: 35px;
    border-bottom: 0;
  }

  .mbody {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;

    .icon {
      display: block;
      width: 30px;
      height: 30px;
      background: transparent no-repeat center;
      background-size: contain;
      margin: 0 auto 10px;

      &.warning {
        background-image: url("../../assets/img/icon_warning.svg");
      }
    }

    .msg {
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
    }

    .info {
      font-size: 13px;
      color: #8c8c8c;
      line-height: 20px;
    }
  }

  .mfoot {
    text-align: center;

    a.move {
      color: #fff;
      border: #f05940 solid 1px;
      background: #f05940;
    }

    a.submit {
      border: #f05940 solid 1px;
      background: #fff;
      color: #f05940;
    }
  }
}
</style>
