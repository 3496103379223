<template>
  <div id="cart">
    <tmpl_header :hide="['shopcart', 'nav']" :subtitle="'购物车'" />

    <div class="containter">
      <ul class="thead">
        <li
          class="check"
          :class="{ checkall: checkall }"
          @click="checkAll"
        ></li>
        <li @click="checkAll">全选</li>
        <li>商品信息</li>
        <li>规格</li>
        <li>单价（元）</li>
        <li>数量</li>
        <li>小计（元）</li>
        <li>操作</li>
      </ul>

      <div class="tbody">
        <div v-if="products.length == 0" class="empty">
          还没有加入任何商品，快去逛一逛吧
        </div>
        <ul
          v-for="(pro, i) in products"
          :key="pro.shoppingCartId"
          :class="{ checked: pro.checked }"
        >
          <li class="check" @click="check" :data-i="i"></li>
          <li>
            <router-link :to="'/goods/view/' + pro.productMap.goodsInfoId"
              ><img :src="pro.productMap.goodsInfoImgId"
            /></router-link>
          </li>
          <li>
            <router-link :to="'/goods/view/' + pro.productMap.goodsInfoId">
              <strong>{{ pro.productMap.goodsInfoName }}</strong></router-link
            >
          </li>
          <li><span></span></li>
          <li>
            <i>{{ pro.productMap.goodsInfoPreferPrice }}</i>
          </li>
          <li>
            <div class="numer">
              <a class="reduce" @click="reduce(i)"></a>
              <span
                ><input
                  :disabled="pro.saving"
                  type="text"
                  v-model="pro.goodsNum"
                  @change="changeNum(i)"
              /></span>
              <a class="increase" @click="increase(i)"></a>
            </div>
            <span>{{ pro.wareStock > 1 ? "有货" : "无货" }}</span>
          </li>
          <li>
            <b>{{ formatPrice(pro.total) }}</b>
          </li>
          <li>
            <a @click="remove(i)" class="remove">删除</a>
            <a @click="favor(i)" class="favor">移入收藏</a>
          </li>
        </ul>
      </div>

      <ul class="tfoot">
        <li
          class="check"
          :class="{ checkall: checkall }"
          @click="checkAll"
        ></li>
        <li class="operates">
          <a @click="checkAll">全选</a>
          <a class="remove" @click="removeChecked">删除</a>
          <a href="javascript:;">清除失效商品</a>
          <a href="javascript:;" @click="favorChecked">移入收藏</a>
        </li>
        <li class="total">
          <span class="num"
            >已选择<b>{{ count }}</b
            >件商品</span
          >
          <span class="price"
            >总价：<b>{{ formatPrice(total) }}</b></span
          >
          <a href="javascript:;" class="settle" @click="submit">去结算</a>
        </li>
      </ul>

      <section class="recommends" v-if="recommends.length > 0">
        <h3><i />智能推荐<i /></h3>
        <ul class="clear">
          <li v-for="pro in recommends" :key="pro.goodsproductId">
            <div class="pro">
              <img :src="pro.goodsproductImgsrc" />
              <p>{{ pro.goodsproductPrice }}</p>
              <strong>{{ pro.goodsproductName }}</strong>
              <div class="numer">
                <input type="text" value="1" />
                <a href="javascript:;" class="increase"></a>
                <a href="javascript:;" class="reduce"></a>
              </div>
              <button class="buy" type="button">加入购物车</button>
            </div>
          </li>
        </ul>
      </section>
    </div>

    <cart_remove
      :show="showCartRemoveModal"
      @onCancel="showCartRemoveModal.cancel"
      @onFavor="showCartRemoveModal.favor"
      @onRemove="showCartRemoveModal.remove"
    />

    <tmpl_footer />
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import cart_remove from "../../components/modal/cart_remove.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: true,
      products: [],
      recommends: [],

      checkall: false,
      total: 0,
      count: 0,
      checked: [],

      showCartRemoveModal: false,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    cart_remove,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    if (!this.username)
      return this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/cart") },
      });

    api.all([this.loadCart()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadCart: function () {
      return api.post(apis.common.shoppingCart, {}).then((res) => {
        this.products = res.map((item) => {
          item.checked = false;
          item.saving = false;
          item.total =
            Math.round(item.productMap.goodsInfoPreferPrice * 100) *
            item.goodsNum;
          return item;
        });
      });
    },

    formatPrice: function (price) {
      let fen = price % 100;
      return Math.floor(price / 100) + "." + (fen < 10 ? "0" : "") + fen;
    },

    check(e) {
      var i = (e.currentTarget && e.currentTarget.dataset.i) || -1;
      if (i < 0) return;
      this.products[i].checked = !this.products[i].checked;

      this.doChecked();
    },

    checkAll() {
      this.doChecked(!this.checkall);
    },

    doChecked(check) {
      this.checkall = true;
      this.total = 0;
      this.count = 0;
      this.checked = [];
      this.products.map((item) => {
        if (check === true || check === false) item.checked = check;
        if (!item.checked) {
          this.checkall = false;
        } else {
          this.checked.push(item.shoppingCartId);
          this.count += parseInt(item.goodsNum, 10);
          this.total += item.total;
        }
      });
    },

    // 更改数量
    reduce(i) {
      const goods = this.products[i];
      const num = goods.goodsNum;
      if (goods.saving || num <= 1) return;
      this.products[i].goodsNum = num - 1;

      this.changeNum(i);
    },

    increase(i) {
      const goods = this.products[i];
      const num = goods.goodsNum;
      if (goods.saving || num >= goods.wareStock) return;
      this.products[i].goodsNum = num + 1;

      this.changeNum(i);
    },

    changeNum(i) {
      const goods = this.products[i];
      let num = goods.goodsNum;
      if (!num || num <= 0) num = 1;
      if (num >= goods.wareStock) num = goods.wareStock;
      num = parseInt(num, 10);
      if (isNaN(num)) num = 1;

      this.products[i].goodsNum = num;
      this.products[i].total =
        Math.round(goods.productMap.goodsInfoPreferPrice * 100) *
        this.products[i].goodsNum;
      this.products[i].saving = true;

      api
        .post(apis.cart.num, {
          shoppingCartId: goods.shoppingCartId,
          goodsId: goods.productMap.goodsInfoId,
          num: parseInt(num, 10),
        })
        .then(() => {
          this.products[i].saving = false;
          if (goods.checked) {
            this.doChecked();
            store.dispatch("needUpdateCart");
          }
        });
    },

    favor(i) {
      const that = this;
      const goods = that.products[i];

      that
        ._favor([
          {
            goodsId: goods.productMap.goodsInfoId,
            followPrice: goods.productMap.goodsInfoPreferPrice,
          },
        ])
        .then(() => {
          that.showCartRemoveModal = false;
          store.dispatch("toast", "已移入收藏");
          that._remove([goods.shoppingCartId]).then(() => {
            store.dispatch("needUpdateCart");
            that.products = that.products.filter((item) => {
              return item.shoppingCartId != goods.shoppingCartId;
            });
          });
        });
    },

    favorChecked() {
      const that = this;
      let ids = [],
        follows = [];
      that.products.map((item) => {
        if (item.checked) {
          ids.push(item.shoppingCartId);
          follows.push({
            goodsId: item.productMap.goodsInfoId,
            followPrice: item.productMap.goodsInfoPreferPrice,
          });
        }
      });
      that._favor(follows).then(() => {
        that.showCartRemoveModal = false;
        store.dispatch("toast", "已移入收藏");
        that._remove(ids).then(() => {
          store.dispatch("needUpdateCart");
          that.products = that.products.filter((item) => {
            return ids.indexOf(item.shoppingCartId) === -1;
          });
        });
      });
    },

    remove(i) {
      const that = this;
      const goods = that.products[i];
      that.showCartRemoveModal = {
        cancel: () => {
          that.showCartRemoveModal = false;
        },
        favor: () => {
          that.favor(i);
        },
        remove: () => {
          that._remove([goods.shoppingCartId]).then(() => {
            that.showCartRemoveModal = false;
            store.dispatch("needUpdateCart");
            that.products = that.products.filter((item) => {
              return item.shoppingCartId != goods.shoppingCartId;
            });
          });
        },
      };
    },

    removeChecked() {
      const that = this;
      let ids = [];
      this.products.map((item) => {
        if (item.checked) {
          ids.push(item.shoppingCartId);
        }
      });
      that.showCartRemoveModal = {
        cancel: () => {
          that.showCartRemoveModal = false;
        },
        favor: that.favorChecked,
        remove: () => {
          that._remove(ids).then(() => {
            that.showCartRemoveModal = false;
            store.dispatch("needUpdateCart");
            that.products = that.products.filter((item) => {
              console.log(ids);
              return ids.indexOf(item.shoppingCartId) === -1;
            });
          });
        },
      };
    },

    _favor(follows) {
      return api.post(apis.center.addFavor, {
        follows: follows,
      });
    },

    _remove(ids) {
      return api.post(apis.cart.del, {
        shoppingCartIds: ids,
      });
    },

    submit() {
      if (this.count <= 0) return store.dispatch("toast", "请选择商品后结算");

      sessionStorage.setItem("checkedCartIds", JSON.stringify(this.checked));
      this.$router.push({ path: "/cart/submit" });
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./index.less");
</style>
